const HandlerForm = () => {
    const form = document.querySelector('.contact__form');

    form.addEventListener('submit', formSend);

    async function formSend(e) {
        e.preventDefault();

        // Validate the form fields
        const error = await validate();

        if (error) {
            // If there are validation errors, stop here
            return;
        }

        // Check reCAPTCHA
        var recaptchaResponse = grecaptcha.getResponse();
        if (recaptchaResponse.length === 0) {
            appendNode('Please complete the reCAPTCHA', 'error');
            return;
        }

        // If validation passed and reCAPTCHA is completed, proceed with form submission
        let formData = new FormData(form);
        formData.append('g-recaptcha-response', recaptchaResponse);

        form.classList.add('_sending');

        try {
            let response = await fetch('./php/send.php', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                let result = await response.json();
                console.log(result);

                // Display success message
                form.classList.add('thanks')

                setTimeout(() => {
                    form.classList.remove('thanks')
                }, 5000)

                // Reset form and reCAPTCHA
                form.reset();
                grecaptcha.reset();
            } else {
                throw new Error('Server response was not ok.');
            }
        } catch (error) {
            appendNode('An error occurred. Please try again.', 'error');
        } finally {
            form.classList.remove('_sending');
        }
    }
}


const validate = async () => {
    let error = false;
    const name = document.getElementById('name');
    const email = document.getElementById('email');
    const company = document.getElementById('company');
    const text = document.getElementById('text');

    const formFields = {
        name: name.value,
        email: email.value,
        company: company.value,
        text: text.value,
    }

    for (let key in formFields) {
        if (key === 'name') {
            if (!formFields[key].trim()) {
                const parent = name.closest('.contact__form-input-wrapper');
                parent.classList.add('error')

                error = true
            }
        }
        if (key === 'text') {
            if (!formFields[key].trim()) {
                const parent = text.closest('.contact__form-input-wrapper');
                parent.classList.add('error')

                error = true
            }
        }
        if (key === 'email') {
            if (!formFields.email.trim() || !formFields.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                const parent = email.closest('.contact__form-input-wrapper');
                parent.classList.add('error')

                error = true;
            }
        }
    }

    return error
}
export default HandlerForm