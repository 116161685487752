const Validate = () => {
    const field = document.querySelectorAll('.field');
    const emailField = document.querySelector('.email-field');

    field.forEach(el => {
        el.addEventListener('input', (e) => {
            const parent = e.target.closest('.contact__form-input-wrapper');
            if (!e.target.value.trim()) {
                parent.classList.add('error')
            } else {
                parent.classList.remove('error')
            }
        })
    })

    emailField.addEventListener('input', (e) => {
        const parent = e.target.closest('.contact__form-input-wrapper');
        if (!e.target.value.trim()) {
            parent.classList.add('error')
        } else {
            parent.classList.remove('error')
        }
    })

    emailField.addEventListener('change', (e) => {
        const parent = e.target.closest('.contact__form-input-wrapper');
        if (!e.target.value.trim() || !e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            parent.classList.add('error')
        } else {
            parent.classList.remove('error')
        }
    })
}
export default Validate