import '../style/style.scss';
import Mash from './mash';
import WorkSwiper from './swiper';
//import MaskPhone from './mask';
import HandlerForm from './form';
import Validate from './validate'

Mash()

window.addEventListener('DOMContentLoaded', () => {
    WorkSwiper()
    //MaskPhone()
    HandlerForm()
    Validate()
})