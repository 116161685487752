import { Swiper, Autoplay, Navigation } from 'swiper';

const WorkSwiper = () => {
    const tehnology = document.querySelector('.tehnology__list-loop')

    var loopSwiperTehnology = new Swiper(tehnology, {
        wrapperClass: 'swiper-wrapper',
        slideClass: 'swiper-slide',
        modules: [Autoplay],
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        speed: 8000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        observer: true,
        spaceBetween: 40,
        grabCursor: true,
        loop: true,
    })

    const work = document.querySelector('.work__slider-wrapper')
    const workArrowBlock = document.querySelector('.work__slider-arrow-block')

    var swiper = new Swiper(work, {
        wrapperClass: 'swiper-wrapper',
        slideClass: 'swiper-slide',
        modules: [Autoplay, Navigation],
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 30,
        grabCursor: true,
        centeredSlides: true,
        loop: true,
        breakpoints: {
            0: {
                slidesPerView: 1.3,
                slidesPerGroup: 1,
            },
            768: {
                slidesPerView: 3,
                slidesPerGroup: 1,
            },
            1024: {
                slidesPerView: 4,
                slidesPerGroup: 1,
            },
        },
        navigation: {
            nextEl: workArrowBlock.querySelector('.work__slider-arrow-next'),
            prevEl: workArrowBlock.querySelector('.work__slider-arrow-prev'),
        },
    });

}

export default WorkSwiper